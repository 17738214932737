
import React, { useState, useRef } from "react"
import Layout from "../../components/layout"
import { Stack, Grid, Typography, Container } from "@mui/material"
import { QRCodeSVG } from "qrcode.react"

import bikeFile from "../../assets/bikes/bikeA9.glb"
import posterPurple from "../../assets/bikes/poster_purple.png"
import posterGreen from "../../assets/bikes/poster_green.png"
import posterRed from "../../assets/bikes/poster_red.png"

import texturePurple from "../../assets/bikes/bikeA9_BaseColor_Purple.jpg"
import textureGreen from "../../assets/bikes/bikeA9_BaseColor_Green.jpg"
import textureRed from "../../assets/bikes/bikeA9_BaseColor_Red.jpg"

import * as style from "./bike.module.scss"

import bgImage from "../../assets/bikes/neoXR_plain.png"


import {Helmet} from "react-helmet";

const Bike = () => {
  const [modelSrc, setModelSrc] = useState(posterPurple)

  const modelViewerRef = useRef(null)

  const switchSrcHandler = async src => {
    // setModelSrc(src)

    console.log(src)

    const modelViewer = modelViewerRef.current

    const material = modelViewer.model.materials[0]
    const texture = await modelViewer.createTexture(src)
    material.pbrMetallicRoughness["baseColorTexture"].setTexture(texture)
  }

  return (
    <Layout>
      <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.js"></script>
      <script src="https://remove-focus-visible-polyfill.s3.us-east-2.amazonaws.com/focus-visible.js"></script>
      </Helmet>

      <Container>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontFamily: "clash display, sans-serif",
            fontSize: { xs: "1.5rem", md: "3rem" },
            color: "black",
            textAlign: "center",
            lineHeight: "150%",
            marginTop: "130px",
            fontWeight: "600",
          }}
        >
          WebAR Bike Example
        </Typography>
        <Typography
            sx={{
              color: "black",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            Go Virtual Before You Buy
          </Typography>
      </Container>

      <Grid
        container
        spacing={2}
        sx={{
          padding: "100px 0",
        }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            height: "500px",

          }}
        >
          <model-viewer
            ref={modelViewerRef}
            style={{
              width: "100%",
              height: "100%"
            }}
            poster={posterPurple}
            src={bikeFile}
            camera-controls
            ar
            auto-rotate
            ar-modes="webxr scene-viewer quick-look"
            shadow-intensity="1"
            loading
            camera-orbit="0deg 75deg 150%"
            alt="A 3D model of an onestopar"
          >
            <button slot="ar-button" id={style.arButton}>
              <svg
                width="1.4rem"
                height="1.4rem"
                viewBox="0 0 109 109"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M86 5H93C99.0751 5 104 9.92487 104 16V23"
                  stroke="black"
                  stroke-width="9"
                />
                <path
                  d="M104 86L104 93C104 99.0751 99.0751 104 93 104L86 104"
                  stroke="black"
                  stroke-width="9"
                />
                <path
                  d="M23 104L16 104C9.92487 104 5 99.0751 5 93L5 86"
                  stroke="black"
                  stroke-width="9"
                />
                <path
                  d="M5 23L5 16C5 9.92487 9.92487 5 16 5L23 5"
                  stroke="black"
                  stroke-width="9"
                />
                <path
                  d="M23.859 36.5981L54 19.1962L84.141 36.5981V71.4019L54 88.8038L23.859 71.4019V36.5981Z"
                  stroke="black"
                  stroke-width="9"
                />
                <path
                  d="M24.2325 37.2558L54 51.6744M54 51.6744V89.3488M54 51.6744L82.8372 37.2558"
                  stroke="black"
                  stroke-width="9"
                />
              </svg>
              <div> View in AR </div>
            </button>

            <button
              className={style.Hotspot}
              slot="hotspot-1"
              data-position="0.4281361390580037m 0.9095354256091585m -0.2234843216998361m"
              data-normal="-0.7146808631036999m 0.5005459013090727m -0.48855405493766885m"
              data-visibility-attribute="visible"
            >
              <div className={style.HotspotAnnotation}>HBA9-420mm</div>
            </button>

            <button
              className={style.Hotspot}
              slot="hotspot-13"
              data-position="-0.5006797757233472m 0.2785621025749201m 0.0973384959854613m"
              data-normal="-0.20722927697804888m -0.81656917710081m 0.5387678588892079m"
              data-visibility-attribute="visible"
            >
              <div className={style.HotspotAnnotation}>FC force D1 DUB 1725 4633</div>
            </button>

            <div slot="progress-bar"></div>
          </model-viewer>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            height: "500px",
            padding: "20px",
            paddingLeft: "35px !important",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontWeight: "600",
            }}
          >
            Disc Road Bike Triaero A9 Discolored FORCE ETAP AXS
          </Typography>
          <Typography
            sx={{
              fontFamily: "clash display, sans-serif",
              fontSize: { xs: "1rem", md: "1.5rem" },
              color: "black",
              fontWeight: "600",
            }}
          >
            $4,230.00
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontWeight: "600",
            }}
          >
            Size
          </Typography>
          <select>
            <option value="46">46</option>
            <option value="48">48</option>
            <option value="50">50</option>
            <option value="52">52</option>
            <option value="54">54</option>
          </select>
          <Typography
            sx={{
              color: "black",
              fontWeight: "600",
            }}
          >
            Colors
          </Typography>
          <Stack direction="row" spacing={2}>
            <img
              style={{ width: "25%", cursor: "pointer" }}
              onClick={() => {
                switchSrcHandler(texturePurple)
              }}
              src={posterPurple}
            ></img>
            <img
              style={{ width: "25%", cursor: "pointer" }}
              onClick={() => {
                switchSrcHandler(textureGreen)
              }}
              src={posterGreen}
            ></img>
            <img
              style={{ width: "25%", cursor: "pointer" }}
              onClick={() => {
                switchSrcHandler(textureRed)
              }}
              src={posterRed}
            ></img>
          </Stack>
          {/* <QRCodeSVG value={window.location.href} />, */}
          <Typography
            sx={{
              color: "black",
              fontWeight: "400",
            }}
          >
            Scan to see real size bike on your Mobile devices
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Bike
