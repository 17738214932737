// extracted by mini-css-extract-plugin
export var Hotspot = "bike-module--Hotspot--7fi+n";
export var HotspotAnnotation = "bike-module--HotspotAnnotation--eRGzA";
export var active = "bike-module--active--b98BR";
export var arButton = "bike-module--arButton--WP2cj";
export var bike_container = "bike-module--bike_container--Md36N";
export var bike_lable_text = "bike-module--bike_lable_text--fwh59";
export var bike_price = "bike-module--bike_price--Sd5wu";
export var bike_title = "bike-module--bike_title--25sO4";
export var box = "bike-module--box--dqz9j";
export var container = "bike-module--container--Dd2MW";
export var demo = "bike-module--demo--POn+g";
export var dot = "bike-module--dot--S3gVG";
export var listGroupItem = "bike-module--list-group-item--d1-3C";
export var offanotation = "bike-module--offanotation--YHQ51";
export var qrcode = "bike-module--qrcode--X9HcQ";
export var qrcode_text = "bike-module--qrcode_text---ZPiv";